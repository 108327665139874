<template>
  <el-row class="audit-container">
    <el-row :gutter="20" class="audit-row">
      <el-col :span="3" class="audit-left">
        <!-- 审核类型 -->
        <h4>{{ $t('auditManage.AuditType') }}</h4>
        <ul>
          <li v-for="(item, index) of auditTypeList" :key="index" :class="index === currentIndex ? 'current' : ''"
            @click="navClick(item.auditType, index, item.auditTypeName)">
            {{ lang === 'en' ? item.auditTypeNameEn : item.auditTypeName }}
            <span v-if="item.count">{{ item.count }}</span>
          </li>
        </ul>
      </el-col>
      <el-col :span="21" class="audit-right">
        <el-tabs v-model="operation" @tab-click="handleClick">
          <!-- 待审核 -->
          <el-tab-pane :label="$t('auditManage.PendingApproval')" name="1"></el-tab-pane>
          <!-- 已审核 -->
          <el-tab-pane :label="$t('auditManage.Approved')" name="2"></el-tab-pane>
          <!-- 我发起的 -->
          <el-tab-pane :label="$t('auditManage.SubmittedByMe')" name="3"></el-tab-pane>
          <!-- 抄送我的 -->
          <el-tab-pane :label="$t('auditManage.CopiedMe')" name="4"></el-tab-pane>
        </el-tabs>
        <p class="area-radio">
          <!-- 展示所属地区 -->
          <el-radio v-model="areaRadio" @click.native.prevent="clickBlank(areaRadio)" label="0">
            {{ $t('auditManage.ShowRegion') }}
          </el-radio>
        </p>
        <!-- 筛选表单 -->
        <AuditSearchForm :type="queryForm.auditType" :operation="+operation" ref="auditSearchForm"
          @params-change="paramsChange" @update:pageNumber="pageNumberChange">
          <el-button size="medium" type="primary" @click="batchEdit" v-if="showBatch">
            批量审核
          </el-button>
        </AuditSearchForm>
        <ErpTable :page-number.sync="queryForm.pageNo" :page-size.sync="queryForm.pageLe" :table-data="tableData"
          :total="total" @query="fetchData" @update:pageSize="pageSizeChange" @update:pageNumber="pageNumberChange"
          :extraHeight="
            (queryForm.auditType !== 9 && queryForm.auditType !== 11) ||
            (queryForm.auditType === 9 && operation === 3) ||
            (queryForm.auditType === 11 && operation === 3)
              ? 80
              : 0
          " @selection-change="handleSelectionChange">
          <!--批量选择-->
          <el-table-column type="selection" width="70" v-if="showBatch"></el-table-column>
          <el-table-column v-for="(item, index) in finallyColumns" :key="index"
            :label="lang === 'en' ? item.labelEn || '--' : item.label" show-overflow-tooltip :width="item.width"
            min-width="180" :resizable="false" align="center" :prop="item.prop">
            <template #default="{ row }">
              <span v-if="item.prop === 'auditNumber'" class="span-ovh">
                {{ row.auditNumber || '--' }}
              </span>
              <span v-else-if="
                  item.prop === 'customerCode' &&
                  (leftCurrentText === '公海审核' ||
                    leftCurrentText === '客户黑名单审核')
                " class="span-ovh">
                {{ row.remaks || '--' }}
              </span>
              <span v-else-if="item.prop === 'subName'" class="span-ovh">
                {{
                lang === 'en' ? row.subNameEn || '--' : row.subName || '--'
                }}
              </span>
              <!-- 订单总金额 -->
              <span v-else-if="item.prop === 'totlePrice'">{{
                row.auditContent.totlePrice | orderMoneyFormat }}</span>
              <!-- 创建时间 -->
              <span v-else-if="item.prop === 'createTime'" class="span-ovh">
                {{ row.auditContent.createTime || '--' }}
              </span>
              <span v-else-if="item.prop === 'subTime'" class="span-ovh">
                {{ row.subTime || '--' }}
              </span>
              <span v-else-if="item.prop === 'area'" class="span-ovh">
                {{ row.area | transArea }}
              </span>
              <!-- 申请时间 -->
              <span v-else-if="item.prop === 'applyTime'" class="span-ovh">
                {{ row.auditContent.applyTime || '--' }}
              </span>
              <span v-else-if="item.prop === 'totalPayAmount'" class="span-ovh">
                <span :class="row.auditContent.entryType === 1 ? 'red' : ''">
                  {{ row.auditContent.entryType === 1 ? '-' : ''
                  }}{{ utils.numberFormat(row.auditContent.totalPayAmount, 2) }}
                </span>
              </span>
              <!-- 收付款方式 -->
              <span v-else-if="item.prop === 'payee'" class="span-ovh">
                {{
                row.auditContent.payee === 2 ? '付款方式' : '收款方式' || '--'
                }}
              </span>
              <!-- 供应商名称 -->
              <el-row v-else-if="item.prop === 'supplierName'" class="supplierName-row" type="flex" align="middle"
                justify="center">
                <tooltip-over class="c_pointer supplierName-span" v-if="row.auditContent.supplierName"
                  :content="row.auditContent.supplierName || '--'" refName="tooltipOver5"></tooltip-over>

                <SupplierIdentification :value="row.auditContent.supplierIdentification"
                  :isAgreement="row.auditContent.isAgreement" />
              </el-row>
              <span v-else-if="item.prop === 'nowAuditUserName'" class="span-ovh">
                {{ row.nowAuditUserName || '--' }}
              </span>
              <el-tag v-else-if="item.prop === 'status'" :type="row.status | statusFilter" class="c_pointer"
                @click="statusTypeClick(row)">
                {{ auditStatus(row.status) }}
              </el-tag>
              <el-row v-else-if="item.prop === 'orderCode'" class="span-ovh">
                <p v-if="row.auditContent[item.prop]">
                  <span v-for="(item, index) of row.auditContent[item.prop].split(
                      ','
                    )" :key="index" class="page-link d_block" :class="[index > 0 ? 'mt5' : '']"
                    @click="judgeClick(item, row.auditContent.orderType)">
                    {{ item || '--' }}
                  </span>
                </p>

                <span v-else>--</span>
              </el-row>
              <!-- 付款管理采购需求单号跳转 -->
              <el-row v-else-if="item.prop === 'purchasingDemandNumber'" class="span-ovh">
                <div>
                  <span :class="row.auditContent.type == '1' ? 'blue-text' : ''" v-if="
                      row.auditContent.purchasingDemandNumber &&
                      row.auditContent.purchasingDemandNumber.split(',')
                        .length === 1
                    " @click="
                      goPurchaseDetail(
                        row.auditContent.purchasingDemandNumber,
                        row.auditContent.type
                      )
                    ">
                    {{
                    row.auditContent.purchasingDemandNumber &&
                    row.auditContent.purchasingDemandNumber.split(',')[0]
                    }}
                  </span>

                  <el-popover placement="right" trigger="hover" v-else-if="
                      row.auditContent.purchasingDemandNumber &&
                      row.auditContent.purchasingDemandNumber.split(',')
                        .length > 1
                    ">
                    <p v-for="item in row.auditContent.purchasingDemandNumber.split(
                        ','
                      )" :key="item" :class="row.auditContent.type == '1' ? 'blue-text' : ''"
                      @click="goPurchaseDetail(item, row.auditContent.type)">
                      {{
                      row.auditContent.purchasingDemandNumber &&
                      row.auditContent.purchasingDemandNumber.split(',')[0]
                      }}
                    </p>
                    <span slot="reference">
                      <span :class="row.auditContent.type == '1' ? 'blue-text' : ''">
                        {{
                        row.auditContent.purchasingDemandNumber &&
                        row.auditContent.purchasingDemandNumber.split(',')[0]
                        }}
                      </span>
                      <span class="dot-span">
                        {{
                        row.auditContent.purchasingDemandNumber.split(',')
                        .length
                        }}
                      </span>
                    </span>
                  </el-popover>

                  <span v-else>--</span>
                </div>
              </el-row>

              <!-- 关联单号采购订单跳转（入库管理，出库管理，付款管理） 待完善-->
              <el-row v-else-if="item.prop === 'orderNumber'" class="span-ovh">
                <!-- 退货入库，销售出库-订单详情跳转 -->
                <el-row v-if="
                    row.auditContent.typeName === '退货入库' ||
                    row.auditContent.typeName === '销售出库' ||
                    row.auditContent.typeName === '销售出库' ||
                    row.auditContent.payType === 2
                  ">
                  <span v-if="row.auditContent[item.prop]" class="page-link" @click="
                      purchaseOrderNumberClick(row.auditContent[item.prop])
                    ">
                    {{ row.auditContent[item.prop] || '--' }}
                  </span>
                </el-row>
                <!-- 加工入库|退料入库 -->
                <el-row v-else-if="
                    row.auditContent.typeName === '加工入库' ||
                    row.auditContent.typeName === '退料入库'
                  ">
                  <span v-if="row.auditContent[item.prop]" class="page-link"
                    @click="processNumberClick(row.auditContent[item.prop])">
                    {{ row.auditContent[item.prop] || '--' }}
                  </span>
                </el-row>
                <!-- 采购入库，退货出库-采购订单详情跳转 -->
                <el-row v-if="
                    row.auditContent.typeName === '采购入库' ||
                    row.auditContent.typeName === '退货出库' ||
                    row.auditContent.typeName === ' 退货出库' ||
                    row.auditContent.payType === 1
                  ">
                  <p v-if="row.auditContent[item.prop]">
                    <span v-for="(item, index) of row.auditContent[item.prop].split(
                        ','
                      )" :key="index" class="page-link d_block" :class="[index > 0 ? 'mt5' : '']"
                      @click="purchaseOrderNumberClick2(item)">
                      {{ item || '--' }}
                    </span>
                  </p>
                </el-row>
                <!-- 其他入库，其他出库 -->
                <span v-if="
                    !row.auditContent.orderNumber ||
                    row.auditContent.typeName === '其他入库' ||
                    row.auditContent.typeName === '其他出库'
                  ">
                  --
                </span>
              </el-row>

              <!-- 收款账户信息 -->
              <span v-else-if="
                  item.prop === 'accountInfo' && row.auditContent.payType === 2
                ">
                {{ row.auditContent.bankAccount }}
              </span>
              <!-- 交货方式 -->
              <span v-else-if="item.prop === 'deliveryMode'">
                {{ row.auditContent.deliveryMode | deliveryModeFilter }}
              </span>
              <!-- 出库方式 -->
              <span v-else-if="item.prop === 'warehouseOutType'">
                {{ row.auditContent.warehouseOutType | warehouseOutTypeFilter }}
              </span>
              <!-- 费用-收款账户信息 -->
              <el-row v-else-if="item.prop === 'bankDeposit'" class="span-ovh">
                <span v-if="row.auditContent.accountName">
                  {{ row.auditContent.accountName }}/
                </span>
                {{ row.auditContent.bankDeposit }}/{{
                row.auditContent.bankAccount
                }}/{{ row.auditContent.dutyParagraph }}
              </el-row>
              <!-- 收款方 -->
              <span v-else-if="
                  item.prop === 'accountName' && row.auditContent.payType === 2
                ">
                {{ row.auditContent.supplierName }}
              </span>
              <div v-else-if="
                  item.prop === 'accountName' && row.auditContent.payType === 1
                ">
                <el-row type="flex" align="middle" justify="center" class="supplierName-row">
                  <p class="supplierName-p">
                    <span>{{ row.auditContent[item.prop] || '--' }}</span>
                  </p>
                  <el-row class="test-row" v-if="row.auditContent.isAgreement == 1">
                    <el-tag type="danger" size="mini" class="ml5">协议</el-tag>
                  </el-row>
                </el-row>
              </div>
              <!-- 付款管理-收款金额 -->
              <span v-else-if="item.prop === 'amountCollected'" :class="row.auditContent.entryType == 1 ? 'red' : ''">
                {{ row.auditContent.entryType == 1 ? '-' : '' }}
                {{ row.auditContent.amountCollected }}
              </span>
              <span v-else-if="item.prop === 'amountApplication'" :class="row.auditContent.entryType == 1 ? 'red' : ''">
                {{ row.auditContent.entryType == 1 ? '-' : '' }}
                {{ utils.numberFormat(row.auditContent.amountApplication, 2) }}
              </span>
              <!-- 付款管理-收款类型 -->
              <span v-else-if="item.prop === 'financeType'">
                <span v-if="
                    row.auditContent.financeType == 1 &&
                    row.auditContent.entryType == 1
                  ">
                  销售退款
                </span>
                <span v-else>销售订单</span>
              </span>
              <!-- 付款管理-申请时间 -->
              <span v-else-if="item.prop === 'createTime'">
                {{ formatTime(row.auditContent.createTime) }}
              </span>

              <el-row v-else-if="item.prop === 'number' && queryForm.auditType == 3">
                <p>{{ row.auditContent.number }}</p>
                <el-tag v-if="contractFlags[row.auditContent.number]">
                  含盖章合同
                </el-tag>
              </el-row>

              <el-row v-else>
                <span class="span-ovh" v-if="
                    item.prop === 'expectedDeliveryDate' ||
                    item.prop === 'contractDate' ||
                    item.prop === 'payTime'
                  ">
                  <span v-if="row.auditContent[item.prop]">
                    {{ getTime(row.auditContent[item.prop]) }}
                  </span>
                  <span v-else>-</span>
                </span>

                <span class="span-ovh" v-else-if="
                    item.prop === 'totalPriceIncluding' ||
                    item.prop === 'totalPriceExcluding' ||
                    item.prop === 'totalPrice' ||
                    item.prop === 'amountTotal'
                  ">
                  {{ utils.numberFormat(row.auditContent[item.prop], 2) }}
                </span>
                <span class="span-ovh" v-else-if="item.prop === 'payType'">
                  {{
                  row.auditContent[item.prop]
                  | payTypeFilter(row.auditContent.entryType)
                  }}
                </span>
                <!-- 售后管理-售后类型 -->
                <span v-else-if="item.prop === 'type'" class="span-ovh">
                  {{ row.auditContent[item.prop] | customerServiceFilter }}
                </span>
                <!-- 成本调整-调整类型 -->
                <span v-else-if="item.prop === 'costWarehouseType'">
                  {{ row.auditContent[item.prop] | costWarehouseTypeName }}
                </span>
                <!-- 成本调整-申请时间 -->
                <span v-else-if="item.prop === 'appAuditTime'">
                  {{ formatTime(row.auditContent.appAuditTime) }}
                </span>
                <span v-else class="span-ovh">
                  {{ row.auditContent[item.prop] || '--' }}
                </span>
              </el-row>
            </template>
          </el-table-column>
          <!-- 右侧固定列 -->
          <!-- 操作 -->
          <el-table-column align="center" :label="$t('auditManage.Operate')" width="100" fixed="right">
            <template #default="{ row }">
              <!-- 审核:查看 -->
              <el-tooltip :content="
                  operation === '1'
                    ? $t('auditManage.Audit')
                    : $t('auditManage.View')
                " placement="top">
                <el-button type="primary" plain :icon="
                    operation === '1' ? 'el-icon-coordinate' : 'el-icon-search'
                  " circle size="mini" @click="clientFollowClick(row)"></el-button>
              </el-tooltip>
            </template>
          </el-table-column>
        </ErpTable>
      </el-col>
    </el-row>

    <!-- 审核详情 -->
    <el-drawer :title="$t('auditManage.Audit')" :visible.sync="drawer" :before-close="handleClose" size="80%">
      <div v-if="queryForm.operation != 1">
        <!-- 有些审核详情不需要传auditObjectId -->
        <Detail ref="Detail" :leftCurrentText="leftCurrentText" :detailId="detailId" :isBatch="isBatch"
          :passRow="passRow" :payType="payType" :operation="operation" :auditObjectId="auditObjectId" v-if="drawer"
          @handleClose="handleClose" />
      </div>
      <div v-if="queryForm.operation == 1" class="audit-content-wrapper">
        <!-- 审核内容 -->
        <h4>{{ $t('auditManage.AuditContent') }}</h4>
        <Detail ref="Detail" :leftCurrentText="leftCurrentText" :detailId="detailId" :isBatch="isBatch"
              :passRow="passRow" :payType="payType" :isNewPayMent="isNewPayMent" :operation="operation" v-if="drawer"
              @handleClose="handleClose" />
        <!-- 审核进度 -->
        <h4>{{ $t('auditManage.AuditProgress') }}</h4>
        <Audit ref="Audit" :auditObjectId="auditObjectId" @cancelClick="statusClose" />
      </div>
    </el-drawer>

    <!-- 审核进度Drawer -->
    <el-drawer :title="$t('auditManage.AuditProgress')" :visible.sync="statusDrawer" :before-="statusClose">
      <Audit ref="Audit" :auditObjectId="auditObjectId" @cancelClick="statusClose" />
    </el-drawer>
  </el-row>
</template>

<script>
  import { auditInteractor, OrderInteractor, PaymentInteractor } from '@/core'
  import columns from './utils/colums'
  import { auditStatus } from '@/utils/en-match-zh'
  import Detail from './components/detail.vue'
  import Audit from '@/components/Audit/ApprovalsDrawer'
  import SupplierIdentification from '@/components/supplier-identification'
  import AuditSearchForm from './components/audit-search-form'
  import Utils from '@/utils/utils.js'
  import navigateTo, { PAGE_TYPES } from '@/utils/navigate-util'
  import Dicts from '@/views/stock-center/allot-inquire/dict/index.js'
  import tooltipOver from '@/components/base-tooltip'
  import dayjs from 'dayjs'
  import { mapGetters } from 'vuex'
    import { dateTimeFormat } from 'kits'
  let self
  export default {
    name: 'AuditManager',
    components: {
      Detail,
      Audit,
      tooltipOver,
      SupplierIdentification,
      AuditSearchForm,
    },
    data() {
      self = this
      return {
        userInfo: {},
        auditTypeList: [],
        currentIndex: 0,
        operation: '1',
        areaRadio: '0',
        queryForm: {
          auditType: null,
          operation: 1, //状态,0.全选,1.待审核,2.已审核,3.我发起的,4.抄送我的
          status: 0,
          pageNo: 1,
          pageLe: 10, //一页条数
          // userId: userInfo.userId
        },
        tableData: [],
        tableData2: [],
        pageNo: 1, //当前页
        pageLe: 10, //一页条数
        total: 0,
        //
        columns: [],
        //订单
        columnsOrder: columns.order,
        //采购备货
        columnsPurchaseStock: columns.purchaseStock,
        //采购订单
        columnsPurchaseOrder: columns.purchaseOrder,
        // 入库管理
        columnPutInStorage: columns.putInStorage,
        // 出库管理
        columnsOutStorage: columns.outStorage,
        //产品管理
        columnsProduct: columns.product,
        //供应商管理
        columnsSupplier: columns.supplier,
        //收付款方式
        columnsPutPayWay: columns.putPayWay,
        //付款管理
        columnsPay: columns.pay,
        //费用管理
        columnsCost: columns.cost,
        //售后管理
        columnsService: columns.service,
        // 订单结算
        columnsSettle: columns.settle,
        // 订单结算调整
        columnsSettleAdjust: columns.settleAdjust,
        // 收款管理
        columnsPayeeManage: columns.payeeManage,
        // 成本调整
        columnCostAdjust: columns.costAdjust,

        setSelectRows: [],
        leftCurrentText: '订单管理', //左侧当前点击项
        drawer: false,
        detailId: '', //详情id
        passRow: {}, //传递给审核的信息
        statusDrawer: false, //审核进度
        auditObjectId: '', //审核id
        test: [],
        contentText: '审核',
        iconChange: 'el-icon-coordinate',
        payType: null, //付款管理类型，1销售退款，2采购订单
        contractFlags: {},
        isBatch: false, // 是否是批量审核
        isNewPayMent: false, //是否是新的付款类型，兼容老数据
        deliveryModes: Dicts.deliveryModes, //调拨拆线呢交货方式
      }
    },
    filters: {
      numberFilter(val) {
        if (!val && val !== 0) {
          return '--'
        } else {
          return parseFloat(val.toFixed(3))
        }
      },
      deliveryModeFilter(val) {
        return (
          Dicts.deliveryModes.find((item) => item.value == val)?.label || '--'
        )
      },
      warehouseOutTypeFilter(val) {
        return (
          Dicts.typeNames.find((item) => item.type == val)?.typeName || '--'
        )
      },
      statusFilter(status) {
        const statusMap = {
          0: 'info',
          1: 'primary',
          2: 'success',
          3: 'danger',
          4: 'info',
          5: 'success',
          6: 'danger',
        }
        return statusMap[status]
      },
      payTypeFilter(status, entryType) {
        if (status == 1) {
          if (entryType == 1) {
            return '采购退款'
          } else {
            return '采购付款'
          }
        }
        const statusMap = {
          1: '采购付款',
          2: '销售退款',
          3: '网拍',
          4: '信用额度还款',
        }
        return statusMap[status]
      },
      // 售后类型
      customerServiceFilter(status) {
        const statusMap = {
          1: '销售订单退款',
          2: '采购订单退款',
        }
        return statusMap[status]
      },
      // 调整类型
      costWarehouseTypeName(val) {
        const statusMap = {
          1: '出库成本调整',
          2: '入库成本调整',
        }

        return statusMap[val]
      },
      // 地区英文翻译
      transArea(val) {
        if (self.lang === 'en') {
          if (val === '合肥') {
            return 'HeFei'
          } else if (val === '苏州') {
            return 'SuZhou'
          } else if (val === '义乌') {
            return 'YiWu'
          }
        } else {
          return val
        }
      },
    },
    computed: {
      ...mapGetters({
        lang: 'settings/language',
      }),
      showBatch() {
        return this.leftCurrentText == '订单结算' && this.operation == 1
      },
      dragOptions() {
        return {
          animation: 600,
          group: 'description',
        }
      },
      finallyColumns() {
        let arr = columns[
          this.setAndGetColumns().get(this.leftCurrentText)
        ].filter((item) => item.isShow)
        return arr
      },
    },
    created() {
      this.userInfo = localStorage.getItem('setUserInfo')
        ? JSON.parse(localStorage.getItem('setUserInfo'))
        : {}
      if (this.userInfo.tenantId && this.userInfo.userId) {
        this.queryForm.userId = this.userInfo.userId

        this.getListByTenantId()
      } else {
        this.$baseMessage(
          '系统错误，请登录后重试',
          'error',
          false,
          'erp-hey-message-error'
        )
        this.$router.push('/login')
      }
      // 定义额外的查询参数
      this.extraQueryForm = {}
    },
    methods: {
      // 获取公海客户客户编码
      getCustomerCode(row) {
        return (
          row.auditContent.map((item) => item.customerCode).join(',') || '--'
        )
      },
      // 分页size变化
      pageSizeChange(val) {
        this.queryForm.pageNo = 1
        this.queryForm.pageLe = val
      },
      // 分页数量变化
      pageNumberChange(val) {
        this.queryForm.pageNo = val
      },
      pageChange(val) {
        this.$emit('update:pageNumber', val)
        this.$emit('query')
      },
      /**
       * 1.5.5.3 参数变化
       */
      paramsChange(reqData = {}) {
        this.extraQueryForm = reqData
        this.fetchData()
      },
      goPurchaseDetail(orderNumber, type) {
        if (type != 1) {
          return
        }
        orderNumber = orderNumber.split('-')[0].replace('PD', '')
        navigateTo(this, {
          pageType: PAGE_TYPES.salesOrderDetail,
          orderNumber,
          roleJudge: false,
        })
      },
      formatTime(val) {
        return Utils.formatTime(val)
      },
      setAndGetColumns() {
        // 把中文名和列表对应起来
        let columnMap = new Map()
        columnMap.set('订单管理', 'order')
        columnMap.set('采购备货', 'purchaseStock')
        columnMap.set('采购订单', 'purchaseOrder')
        columnMap.set('入库管理', 'putInStorage')
        columnMap.set('出库管理', 'outStorage')
        columnMap.set('产品管理', 'product')
        columnMap.set('供应商管理', 'supplier')
        columnMap.set('收付款方式', 'putPayWay')
        columnMap.set('付款管理', 'pay')
        columnMap.set('费用管理', 'cost')
        columnMap.set('售后管理', 'service')
        columnMap.set('订单结算', 'settle')
        columnMap.set('结算调整', 'settleAdjust')
        columnMap.set('收款管理', 'payeeManage')
        columnMap.set('成本调整', 'costAdjust')
        columnMap.set('客评中心', 'customerCenter')
        columnMap.set('客户审核', 'customerAudit')
        columnMap.set('公海审核', 'seaAudit')
        columnMap.set('客户黑名单审核', 'customerBlackListAudit')
        columnMap.set('调拨审核', 'transfer')
        columnMap.set('盘点审核', 'stockCheck')
        columnMap.set('客户账期', 'accountPeroid')
        return columnMap
      },

      add(m) {
        return m < 10 ? '0' + m : m
      },

      getTime(timestamp) {
        var time = new Date(timestamp)
        var year = time.getFullYear()
        var month = time.getMonth() + 1
        var day = time.getDate()
        return year + '-' + this.add(month) + '-' + this.add(day)
      },

      //左侧审核
      async getListByTenantId() {
        let response = await auditInteractor.getListByTenantId({
          tenantId: this.userInfo.tenantId,
          userId: this.userInfo.userId,
        })
        if (response?.code === '000000') {
          // 英文只显示客评中心
          const data = response.data
          if (this.lang === 'en') {
            this.auditTypeList = data.filter((item) => item.auditType === 16)
            this.auditTypeList[0].auditTypeNameEn = 'Customer Review'
            this.navClick(16, 0, '客评中心')
          } else {
            this.auditTypeList = data
          }
          if (!this.queryForm.auditType) {
            this.queryForm.auditType = response.data[0].auditType
            this.prevAuditType = this.queryForm.auditType
          }
          this.fetchData()
        }
      },

      //获取列表
      async fetchData() {
        this.tableData = []
        this.tableData2 = []
        const reqestData = {
          ...this.extraQueryForm,
          ...this.queryForm,
        }
        let response = await auditInteractor.listPage(reqestData)
        if (response?.code === '000000') {
          this.tableData = response.data.data
          if (this.queryForm.auditType == 3 && this.tableData.length > 0) {
            OrderInteractor.querySealedContractApi(
              this.tableData
                .map((item) => JSON.parse(item.auditContent).number)
                .join(',')
            ).then((res) => {
              if (res?.code === '000000') {
                this.contractFlags = res.data || {}
              }
            })
          } else {
            this.contractFlags = {}
          }
          this.total = response.data.total
          this.tableData.forEach((item) => {
            if (item.auditContent) {
              this.tableData2.push(JSON.parse(item.auditContent))
              item.auditContent = JSON.parse(item.auditContent)
              item.auditContent.applyTime = dayjs(
                item.auditContent.applyTime
              ).format('YYYY-MM-DD HH:mm:ss')
              // 创建时间
              item.auditContent.createTime = item.auditContent.createTime ? dayjs(
                item.auditContent.createTime
              ).format('YYYY-MM-DD HH:mm:ss') : ''
            }
          })
        }
      },

      //左侧nav审核类型点击
      navClick(type, index, name) {
        if (type !== this.prevAuditType) {
          this.$refs?.auditSearchForm?.reset(false)
          this.extraQueryForm = {}
        }
        this.prevAuditType = type
        this.queryForm.auditType = type
        this.currentIndex = index
        this.leftCurrentText = name
        this.queryForm.pageNo = 1
        this.handleClick()
      },
      //右侧头部状态点击
      handleClick() {
        this.queryForm.pageNo = 1
        this.queryForm.operation = Number(this.operation)
        this.columnTable(
          columns[this.setAndGetColumns().get(this.leftCurrentText)],
          this.operation
        )
        if(this.queryForm.operation === 2 && this.queryForm.auditType !== 9 && this.queryForm.auditType !== 11){
          const end = dateTimeFormat('yyyy-MM-dd');
          let start = new Date().getTime() - (3600 * 1000 * 24 * 30);
          start = dateTimeFormat('yyyy-MM-dd',start);
          this.extraQueryForm.startDate = start
          this.extraQueryForm.endDate = end
          this.$refs?.auditSearchForm?.resetFormData(true)
        }else{
          this.$refs?.auditSearchForm?.resetFormData()
          this.extraQueryForm = {}
        }
        this.fetchData()
      },

      //列隐藏显示
      columnTable(columnsType, str) {
        columnsType.forEach((item, i) => {
          item.operation &&
            item.operation.length &&
            item.operation.includes(str)
            ? (columnsType[i].isShow = true)
            : (columnsType[i].isShow = false)
        })
        this.test = [...Object.values(columns)]
        this.areaRadio === '0'
          ? this.setTestShow(this.test, true)
          : this.setTestShow(this.test, false)
      },
      //审核进度查看
      statusTypeClick(row) {
        this.auditObjectId = row.auditObjectId
        this.statusDrawer = true
      },

      statusClose() {
        this.statusDrawer = false
      },
            // 批量审核
      batchEdit() {
        if (this.setSelectRows.length === 0) {
          return this.$message.warning('至少选择一条数据')
        }
        this.passRow = this.setSelectRows.map((row) => {
          const obj = {}
          obj.auditObjectId = row.auditObjectId
          obj.auditType = this.queryForm.auditType
          obj.userId = this.userInfo.userId
          obj.userName = this.userInfo.userName
          obj.tenantId = this.userInfo.tenantId
          obj.settlementOrder = row.auditContent.settlementOrder
          return obj
        })

        this.isBatch = true
        this.detailId = this.setSelectRows
          .map((item) => item.auditContent.settlementId)

        this.drawer = true
      },
      // 选中
      handleSelectionChange(val) {
        this.setSelectRows = val
      },
      // 时间戳转日期
      getFormData(timestamp) {
        const date = new Date(timestamp)
        const year = date.getFullYear()
        let month = date.getMonth() + 1
        month = month < 10 ? '0' + month : month
        const day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
        return `${year}-${month}-${day}`
      },
      //审核查看
      async clientFollowClick(row) {
        if (row?.auditContent?.beforeSupplierVO) {
          if (row.auditContent.beforeSupplierVO.supplierAttestationVO) {
            for (let k in row.auditContent.beforeSupplierVO
              .supplierAttestationVO) {
              let timeKey = [
                'businessLicenseend',
                'businessLicensestard',
                'cardStard',
                'cardEnd',
              ]
              if (
                timeKey.includes(k) &&
                row.auditContent.beforeSupplierVO.supplierAttestationVO[k]
              ) {
                row.auditContent.beforeSupplierVO.supplierAttestationVO[k] =
                  this.getFormData(
                    row.auditContent.beforeSupplierVO.supplierAttestationVO[k]
                  )
              }
            }
          }
          if (row?.auditContent?.beforeSupplierVO?.agreementStartDate) {
            row.auditContent.beforeSupplierVO.agreementStartDate =
              this.getFormData(
                row.auditContent.beforeSupplierVO.agreementStartDate
              )
          }
          if (row?.auditContent?.beforeSupplierVO?.agreementEndDate) {
            row.auditContent.beforeSupplierVO.agreementEndDate =
              this.getFormData(
                row.auditContent.beforeSupplierVO.agreementEndDate
              )
          }
          if (row?.auditContent?.beforeSupplierVO?.payVOS) {
            for (let k of row.auditContent.beforeSupplierVO.payVOS) {
              k['addTime'] = this.getFormData(k['addTime'])
            }
          }
        }
        this.auditObjectId = row.auditObjectId
        this.isBatch = false
        this.passRow = {
          auditObjectId: row.auditObjectId,
          auditType: this.queryForm.auditType,
          userId: this.userInfo.userId,
          userName: this.userInfo.userName,
          tenantId: this.userInfo.tenantId,
          auditContent: row.auditContent,
          internalType: row.internalType,
          subTime: row.subTime,
        }
        this.setCurrentText(row)
        this.detailId = this.detailId ? this.detailId : ''
        console.log(this.leftCurrentText)
        setTimeout(() => {
          this.drawer = true
        }, 400)
      },
      setCurrentText(row) {
        switch (this.leftCurrentText) {
          case '订单管理':
            this.detailId = row.auditContent.orderCode
            break
          case '采购备货':
            this.detailId = row.auditContent.numberPrefix
            break
          case '采购订单':
            this.detailId = row.auditContent.number
            break
          case '入库管理':
            this.detailId = row.auditContent.warehouseInId
            break
          case '出库管理':
            this.detailId = row.auditContent.warehouseOutId
            break
          case '产品管理':
            this.detailId = row.auditContent.productId
            break
          case '供应商管理':
            this.detailId = row.auditContent.supplierId
            break
          case '收付款方式':
            this.detailId = row.auditContent.paymentId
            break
          case '付款管理':
            if (row.auditContent.applicationPayOrderId) {
              this.detailId = {
                detailId: row.auditContent.applicationPayOrderId,
                isNewPayMent: false,
              }
            } else {
              this.detailId = {
                detailId: row.auditContent.payOrderId,
                isNewPayMent: true,
              }
            }

            this.payType = row.auditContent.payType
            break
          case '费用管理':
            this.detailId = row.auditContent.costNumber
            break
          case '售后管理':
            this.detailId = row.orderId
            break
          case '订单结算':
            this.detailId = row.auditContent.settlementId
            break
          case '结算调整':
            this.detailId = row.auditContent.settlementSalesOrderId
            break
          case '收款管理':
            this.detailId = row.auditContent.financeId
            break
          case '成本调整':
            this.detailId = row.auditContent.costAdjustId
            break
          case '客评中心':
            this.detailId = row.auditContent.customerComplainId
            break
          case '调拨审核':
            this.detailId = row.auditContent.transferOrderId
            break
          case '盘点审核':
            this.detailId = row.auditContent.stockTakingId
            break
          // case '客户账期':
          //   this.detailId = row.auditContent.stockTakingId
          //   break
        }
      },
      //审核关闭
      handleClose() {
        this.drawer = false
        this.getListByTenantId()
      },

      //展示所属地区点击
      clickBlank(e) {
        this.test = [...Object.values(columns)]
        if (e === '0') {
          this.areaRadio = '1'
          this.setTestShow(this.test, false)
        } else {
          this.areaRadio = '0'
          this.setTestShow(this.test, true)
        }
      },
      setTestShow(test, state) {
        test.forEach((item, i) => {
          item.forEach((item2, i2) => {
            if (item2.prop === 'area' || item2.prop === 'auditUserArea') {
              this.test[i][i2].isShow = state
            }
          })
        })
      },

      //judgeClick
      judgeClick(code, type) {
        if (type === '2') {
          this.purchaseOrderNumberClick2(code)
        } else {
          this.purchaseOrderNumberClick(code)
        }
      },

      //销售订单号跳转
      async purchaseOrderNumberClick(code) {
        let response = await PaymentInteractor.paymentStatusVOApi({
          orderCode: code,
        })
        if (response?.code == '000000') {
          let orderStatus = response.data.orderStatus
          let routeData = this.$router.resolve({
            path: '/order/orderList/orderDetail',
            query: { orderCode: code, noReturn: true },
          })
          window.open(routeData.href, '_blank')
        }
      },

      async purchaseOrderNumberClick2(code) {
        // 采购订单号跳转
        let response = await PaymentInteractor.dinggetDetailForShowApi({
          businessId: code,
        })
        let data = response.data
        if (response?.code == '000000') {
          let orderStatus = response.data.orderStatus
          let routeData = this.$router.resolve({
            path: '/purchase/purchase/purchaseOrder/purchaseOrderDetail',
            query: {
              id: data.purchaseOrderId,
              noReturn: true,
            },
          })
          window.open(routeData.href, '_blank')
        }
      },

      processNumberClick(orderNumber) {
        return navigateTo(this, {
          orderNumber,
          pageType: 'outprocessingDetail',
        })
      },

      //审核状态匹配
      auditStatus(val) {
        return auditStatus(val)
      },
    },
  }
</script>

<style scoped lang="scss">
  .audit-row {
    height: 100%;
    overflow: hidden;

    .audit-left {
      height: 100%;
      padding: 10px;
      overflow-y: auto;

      h4 {
        border-bottom: 1px solid #ededed;
        padding-bottom: 12px;
        font-size: 15px;
      }

      ul {
        li {
          height: 40px;
          line-height: 40px;
          padding-left: 10%;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: space-between;

          span {
            display: inline-block;
            line-height: 15px;
            font-size: 12px;
            text-align: center;
            background: #f56c6c;
            color: #fff;
            padding: 2px 5px;
            border-radius: 4px;
          }
        }

        li.current {
          color: #1890ff;
        }
      }
    }

    .audit-right {
      position: relative;

      .area-radio {
        position: absolute;
        right: 20px;
        top: 12px;
      }
    }
  }

  .span-ovh {
    width: 100%;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .blue-text {
    text-decoration: underline;
  }

  .supplierName-row {
    .supplierName-span {
      width: 90%;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .supplierName-p {
      flex: 1;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      text-align: center;
    }
  }

  ::v-deep {
    .el-tabs__nav-wrap::after {
      height: 1px;
    }
  }

  .dot-span {
    display: inline-block;
    width: 15px;
    height: 15px;
    line-height: 15px;
    font-size: 12px;
    background: #409eff;
    color: #fff;
    border-radius: 50%;
    cursor: pointer;
    margin-left: 5px;
  }

  .audit-content-wrapper {
    padding-bottom: 100px;
    > h4 {
      padding-left: 30px;
      color: #0486fe;
    }
  }
</style>
