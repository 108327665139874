<!--
  功能：审核高级筛选
  作者：jiangyongqiang
  邮箱：missfoxw@163.com
  时间：2022年08月11日 09:59:17
  版本：v1.0
  修改记录：
  修改内容：
  修改人员：
  修改时间：
-->
<template>
  <div class="wrapper" v-if="type !== 9 && type !== 11">
    <el-form>
      <el-form-item>
        <slot></slot>
      </el-form-item>
      <!-- 审核单号 -->
      <el-form-item
        v-if="type !== 9 && type !== 11"
        class="item"
        style="width: 200px"
      >
        <el-input
          clearable
          placeholder="审核单号"
          v-model="form.auditNumber"
          size="medium"
          @keyup.enter.native="search"
        ></el-input>
      </el-form-item>
      <!-- 单号1 -->
      <el-form-item
        v-if="placeholder1 && type !== 9 && type !== 11"
        class="item"
      >
        <el-input
          clearable
          :placeholder="placeholder1"
          v-model="form.mainNumber"
          size="medium"
          @keyup.enter.native="search"
        ></el-input>
      </el-form-item>
      <!-- 单号2 -->
      <el-form-item
        v-if="placeholder2 && type !== 9 && type !== 11"
        class="item"
      >
        <el-input
          clearable
          :placeholder="placeholder2"
          v-model="form.minorNumber"
          size="medium"
        ></el-input>
      </el-form-item>
      <!-- 供应商名称 -->
      <el-form-item v-if="type === 10" class="item">
        <el-input
          clearable
          placeholder="供应商名称"
          v-model="form.supplierName"
          size="medium"
        ></el-input>
      </el-form-item>
      <!-- 申请人 -->
      <el-form-item v-if="type !== 9 && type !== 11" class="user">
        <el-input
          v-if="lang === 'en'"
          clearable
          :placeholder="$t('auditManage.Applicant')"
          v-model="form.subNameEn"
          size="medium"
          @focus="selSub"
        ></el-input>
        <el-input
          v-else
          clearable
          :placeholder="$t('auditManage.Applicant')"
          v-model="form.subName"
          size="medium"
          @focus="selSub"
        ></el-input>
      </el-form-item>
      <!-- 申请时间 -->
      <el-form-item v-if="type !== 9 && type !== 11" class="item">
        <el-date-picker
          clearable
          size="medium"
          v-model="form.date"
          type="daterange"
          range-separator="~"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
          :start-placeholder="$t('auditManage.StartDate')"
          :end-placeholder="$t('auditManage.DateClosed')"
          align="right"
        ></el-date-picker>
      </el-form-item>
      <el-form-item prop="deliveryMode" v-if="type === 20" class="item">
        <el-select
          v-model="form.deliveryMode"
          clearable
          placeholder="交货方式"
          size="medium"
        >
          <el-option
            v-for="item in deliveryModes"
            :key="item.value"
            :value="item.value"
            :label="item.label"
          ></el-option>
        </el-select>
      </el-form-item>
      <!-- 所属地区 -->
      <el-form-item
        v-if="type !== 9 && type !== 11 && type !== 20"
        class="area"
      >
        <el-select
          clearable
          placeholder="Region"
          v-model="form.area"
          size="medium"
          v-if="lang === 'en'"
        >
          <el-option label="HeFei" value="合肥"></el-option>
          <el-option label="SuZhou" value="苏州"></el-option>
          <el-option label="YiWu" value="义乌"></el-option>
        </el-select>
        <el-select
          v-else
          clearable
          placeholder="所属地区"
          v-model="form.area"
          size="medium"
        >
          <el-option label="合肥" value="合肥"></el-option>
          <el-option label="苏州" value="苏州"></el-option>
          <el-option label="义乌" value="义乌"></el-option>
        </el-select>
      </el-form-item>
      <!-- 产品类型 -->
      <el-form-item v-if="type === 1" class="item productType">
        <el-select
          clearable
          placeholder="产品类型"
          v-model="form.standardCustomizedFlag"
          size="medium"
        >
          <el-option label="订制品" value="1"></el-option>
          <el-option label="标准品" value="2"></el-option>
        </el-select>
      </el-form-item>
      <div class="btn-wrapper">
        <!-- 搜索 -->
        <el-button @click="search" size="medium">
          {{ $t('cusCenter.Search') }}
        </el-button>
        <!-- 重置  -->
        <el-button @click="reset" size="medium">
          {{ $t('cusCenter.Reset') }}
        </el-button>
      </div>
    </el-form>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { dateTimeFormat } from 'kits'
  import Dicts from '@/views/stock-center/allot-inquire/dict/index.js'
  const type2placeholder1 = {
    1: '请输入销售单号', //订单管理
    9: '', //售后管理
    2: '采购需求单号', // 采购备货
    3: '采购单号', //采购订单
    4: '入库单号', //入库管理
    5: '出库单号', //出库管理
    6: '产品编码/产品名称', //产品管理
    7: '付款单号', // 付款管理
    10: '', //供应商管理
    11: '', //收付款方式
    13: '收款单号', //收款管理
    8: '费用单号', // 费用管理,
    12: '结算单号', //订单结算
    15: '调整单号', //订单结算
    20: '调拨单号', //调拨审核
    21: '盘点单号', //盘点审核
  }

  const type2placeholder2 = {
    1: '',
    9: '',
    2: '',
    3: '',
    4: '采购单号',
    5: '',
    6: '',
    7: '采购单号/采购需求单号',
    10: '',
    11: '',
    13: '销售单号',
    8: '采购单号/销售单号',
    12: '', //订单结算
    15: '',
  }
  export default {
    name: 'AuditSearchForm',
    props: {
      type: {
        //审核类型
        type: Number,
        default: 1,
      },
      operation: {
        //当前选中的tab
        type: Number,
        default: 1,
      },
    },
    components: {},
    // 组件状态值
    data() {
      return {
        form: {
          // 搜索表单
          mainNumber: '', //单号1
          minorNumber: '', //单号2
          subName: '', //申请人
          subNameEn: '', //申请人
          subIds: [], //申请人id
          date: '', //选择的日期
          area: '', // 所属地区
          // status: '', // 审核状态
          supplierName: '', //供应商名称
          auditNumber: '',
          deliveryMode: '', //交货方式
        },

        // 审核状态列表
        statusList: [
          {
            code: 1,
            label: '审核中',
          },
          {
            code: 2,
            label: '审核通过',
          },
          {
            code: 3,
            label: '审核驳回 ',
          },
          {
            code: 4,
            label: '已撤销',
          },
        ],
        deliveryModes: Dicts.deliveryModes, //调拨拆线呢交货方式
      }
    },
    computed: {
      ...mapGetters({
        lang: 'settings/language',
      }),
      placeholder1() {
        // 根据审核类型计算单号默认文本
        return type2placeholder1[this.type]
      },
      placeholder2() {
        // 根据审核类型计算单号默认文本
        return type2placeholder2[this.type]
      },
    },

    watch: {
      'form.subName': function (val) {
        // 清空 subIds
        if (!val) {
          this.form.subNameEn = ''
          this.form.subIds = []
        }
      },
      'form.subNameEn': function (val) {
        // 清空 subIds
        if (!val) {
          this.form.subName = ''
          this.form.subIds = []
        }
      }
    },

    methods: {
      // 搜索
      search() {
        const [startDate, endDate] = this.form.date
          ? this.form.date
          : [null, null]
        this.$emit('update:pageNumber', 1)
        this.$emit('params-change', { ...this.form, startDate, endDate})
      },
      // 重置
      reset(isEmit) {
        this.form = this.$options.data().form
        if (isEmit !== false) {
          if(this.operation === 2){
            this.getOneMonthFn()
            const [startDate, endDate] = this.form.date
            ? this.form.date
            : [null, null]
            this.$emit('params-change', { ...this.form, startDate, endDate })
          }else{
            this.$emit('params-change', { ...this.form})
          }
         
        }
      }, 
      // 选择申请人
      selSub() {
        event.target.blur()
        const { subIds, subName, subNameEn } = this.form
        this.$userChoose(this)
          .showPromisedUserAddEdit({
            str: '',
            roleId:
              subIds?.length > 0
                ? subIds.map((item, index) => {
                    return {
                      id: item,
                      name: subName.split(',')[index],
                      englishName: subNameEn.split(',')[index],
                    }
                  })
                : '',
            canSelectZeroUser: false,
            singleSelect: false,
          })
          .then((users) => {
            if (Array.isArray(users) && users.length > 0) {
              this.form.subName = users.map((i) => i.name).join(',')
              this.form.subNameEn = users.map((i) => i.englishName).join(',')
              this.form.subIds = users.map((i) => i.id)
            }
          })
      },
      // 获取最近一个月时间
      getOneMonthFn(){
        const end = dateTimeFormat('yyyy-MM-dd');
        let start = new Date().getTime() - (3600 * 1000 * 24 * 30);
        start = dateTimeFormat('yyyy-MM-dd',start);
        this.form.date =  [start,end]
      },
      // 重新表单数据
      resetFormData(bol=false){
        this.form = this.$options.data().form;
        if(bol)this.getOneMonthFn()
      }
    }
  }
</script>

<style scoped lang="scss">
  .wrapper {
    display: flex;

    .el-form {
      display: flex;
      flex: 1;
      .el-form-item.item {
        /* flex: 1; */
        /* max-width: 300px; */
        .el-input,
        .el-select,
        .el-date-editor {
          width: 100%;
        }
        margin: 0 5px;
        .el-date-editor {
          height: 35px;
        }
      }
      .el-form-item.area,
      .el-form-item.user,
      .el-form-item.productType {
        width: 120px;
      }
    }
    .btn-wrapper {
      width: 200px;
      margin-left: 10px;
    }
  }
</style>
