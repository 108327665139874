<template>
  <div class="table-container" v-if="$route.meta.showParent">
    <!-- header -->
    <el-row
      class="page-header"
      type="flex"
      align="middle"
      justify="space-between"
    >
      <el-col :span="20">
        <!-- 默认展示 -->
        <el-row v-if="!selectRows.length">
          <el-button icon="el-icon-plus" type="primary" @click="addClick">
            新增
          </el-button>
          <el-input
            type="text"
            placeholder="请输入供应商编码/名称"
            clearable
            v-model="requireForm.supplierCode"
            @keyup.enter.native="searchHandleClick"
            @input="searchInput"
            class="width-200 ml10"
          ></el-input>
          <el-button class="ml10 pub-h-36" @click="searchHandleClick">
            搜索
          </el-button>
          <el-button class="pub-h-36" @click="seniorSearch">高级筛选</el-button>

          <el-button
            class="ml10 pub-h-36"
            plain
            @click="addBlackList"
            v-allowed="['Supplier:Add:Black']"
          >
            加入黑名单
          </el-button>
        </el-row>
        <!-- 表格勾选展示 -->
        <el-row type="flex" align="middle" v-else>
          <p>
            已选择
            <strong class="f_s_18 f_w_7">{{ selectRows.length }}</strong>
            个供应商
          </p>
          <el-button
            v-show="isBlack"
            class="ml10 pub-h-36"
            type="primary"
            plain
            @click="addBlackList"
          >
            加入黑名单
          </el-button>
        </el-row>
      </el-col>
      <el-col :span="4" style="text-align: right">
        <el-popover
          ref="showOption"
          popper-class="custom-table-checkbox"
          trigger="hover"
        >
          <erp-draggable v-bind="dragOptions" :list="columns">
            <el-checkbox
              v-for="(item, index) in columns"
              :key="item + index"
              v-model="item.isShow"
              :disabled="item.checkable === false"
              :label="item.label"
            >
              {{ item.label }}
            </el-checkbox>
          </erp-draggable>
          <!-- 保存自定义显示列 -->
          <div class="save-btn">
            <el-button
              type="primary"
              class="ml10"
              size="mini"
              plain
              @click="operateSave"
            >
              保存
            </el-button>
          </div>

          <template #reference>
            <el-tooltip
              effect="dark"
              content="列表可拖动排序"
              placement="top-start"
            >
              <el-button plain type="primary">自定义显示</el-button>
            </el-tooltip>
          </template>

        </el-popover>
      </el-col>
    </el-row>

    <!-- table -->
    <el-row class="mt20 main-height">
      <el-row class="table-height-box">
        <ErpTable
          :page-number.sync="pageNo"
          :page-size.sync="pageLe"
          :table-data="tableData"
          :total="total"
          @fetchData="fetchData"
          class="table-fixed"
          @query="fetchData"
          @selection-change="setSelectRows"
          :key="customTableKey"
        >
          <el-table-column align="center" type="selection" width="55" />
          <el-table-column
            v-for="(item, index) in finallyColumns"
            :key="index"
            :label="item.label"
            show-overflow-tooltip
            :sortable="item.sortable"
            :width="item.width"
            min-width="120"
            :resizable="false"
            align="center"
            :prop="item.prop"
          >
            <template #default="{ row }">
              <!-- 供应商编码 -->
              <span
                :class="row.supplierCode ? 'blue-text' : ''"
                v-if="item.prop === 'supplierCode'"
                @click="supplierCodeClick(row.supplierId)"
              >
                {{ row.supplierCode || '--' }}
              </span>
              <!-- 供应商名称 -->
              <el-row
                v-else-if="item.prop === 'supplierName'"
                type="flex"
                align="middle"
                justify="center"
                class="supplierName-row"
              >
                <p class="supplierName-p">{{ row.supplierName || '--' }}</p>
                <el-row class="test-row">
                  <el-tag
                    v-if="row.supplierIdentification === '0'"
                    type="primary"
                    size="mini"
                    class="ml5"
                  >
                    线下
                  </el-tag>
                  <el-tag
                    v-if="row.supplierIdentification === '1'"
                    type="success"
                    size="mini"
                    class="ml5"
                  >
                    网拍
                  </el-tag>
                  <el-row
                    v-if="
                      row.supplierIdentification === '0,1' ||
                      row.supplierIdentification === '1,0'
                    "
                  >
                    <el-tag type="primary" size="mini" class="ml5">线下</el-tag>
                    <el-tag type="success" size="mini" class="ml5">网拍</el-tag>
                  </el-row>
                  <el-tag
                    v-if="row.isAgreement === 1"
                    type="danger"
                    size="mini"
                  >
                    协议
                  </el-tag>
                </el-row>
              </el-row>
              <!-- 供应商等级 -->
              <el-rate
                v-else-if="item.prop === 'supplierGrade'"
                v-model="row.supplierGrade"
                disabled
                text-color="#ff9900"
              ></el-rate>

              <!-- 状态 -->
              <el-row v-else-if="item.prop === 'status'">
                <AuditProcess
                  v-if="row.status !== 7"
                  :text="supplierAuditStatus(row.status)"
                  :type="row.status | statusFilter"
                  :showProcess="
                    row.status == 5 ||
                    row.auditProcess === 6 ||
                    row.auditProcess === 7
                      ? false
                      : true
                  "
                  :auditParams="{
                    auditType,
                    auditObjectId: row.supplierId,
                  }"
                ></AuditProcess>
                <el-tooltip
                  v-else
                  :content="'原因：' + row.blacklistReasons"
                  placement="top"
                >
                  <span class="c_pointer">黑名单</span>
                </el-tooltip>
              </el-row>
              <span v-else-if="item.prop === 'total'">
                {{ row.totalEx ? '￥' : '' }}{{ row.totalEx || '--' }}
              </span>
              <!-- 其他 -->
              <span v-else>{{ row[item.prop] || '--' }}</span>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            label="操作"
            width="120"
            fixed="right"
            :resizable="false"
          >
            <template #default="{ row }">
              <el-row v-if="row.status !== 7">
                <el-tooltip
                  content="编辑"
                  placement="top"
                  v-if="row.status !== 1"
                >
                  <el-button
                    type="primary"
                    plain
                    icon="el-icon-edit"
                    circle
                    size="mini"
                    @click="editDetail('edit', row.supplierId)"
                  ></el-button>
                </el-tooltip>
                <!-- 撤销 -->
                <el-tooltip
                  content="撤销"
                  placement="top"
                  v-if="row.status === 1"
                >
                  <el-button
                    type="primary"
                    plain
                    circle
                    size="mini"
                    @click="revokeClick(row)"
                  >
                    <i class="iconfont icon-quxiao f_s_12"></i>
                  </el-button>
                </el-tooltip>
              </el-row>
              <span v-else>--</span>
            </template>
          </el-table-column>
        </ErpTable>
        <!-- :is-requesting="isRequesting" -->
      </el-row>
    </el-row>

    <!-- 高级筛选Drawer -->
    <el-drawer
      title="高级筛选"
      :visible.sync="drawer"
      :before-="seniorSearchClose"
    >
      <SupplierManageSearch
        ref="PublicClentSearch"
        @cancelClick="seniorSearchClose"
        @searchClick="searchClick"
      />
    </el-drawer>

    <!-- 加入黑名单弹窗 -->
    <el-dialog
      title="加入黑名单"
      :visible.sync="dialogBlackVisible"
      width="500px"
      @close="closeBlackDialog"
    >
      <el-form :model="blackForm" :rules="blackRules" ref="blackForm">
        <el-form-item label="理由" prop="blacklistReasons" label-width="50px">
          <el-input
            type="textarea"
            :autosize="{ minRows: 4, maxRows: 6 }"
            maxlength="500"
            show-word-limit
            v-model.trim="blackForm.blacklistReasons"
            placeholder="请输入"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" :loading="loading" @click="saveBlackList">
          确 认
        </el-button>
        <el-button @click="closeBlackDialog">取 消</el-button>
      </div>
    </el-dialog>

    <!-- 撤销弹窗 -->
    <el-dialog
      title="撤销原因"
      :visible="cancelVisible"
      append-to-body
      width="500px"
      @close="onCancel"
      modal-append-to-body
    >
      <span style="color: red; position: absolute; left: 10px">*</span>
      <el-input
        type="textarea"
        :rows="3"
        placeholder="请输入撤销原因"
        maxlength="500"
        show-word-limit
        v-model="cancelReason"
      ></el-input>
      <div class="footer mt30 t_a_c">
        <el-button type="primary" @click="cancelSure">确 认</el-button>
        <el-button @click="onCancel">取 消</el-button>
      </div>
    </el-dialog>
  </div>
  <div v-else class="pro-container">
    <router-view />
  </div>
</template>

<script>
import ErpDraggable from 'vuedraggable'
import BaseTable from '@/components/baseTable'
import SupplierManageSearch from './components/supplier-manage-search'
import CustomCache from '@/utils/custom-cache'
import customTableMixin from '@/utils/custom-table-mixin'
import { mapGetters } from 'vuex'
import { supplierAuditStatus } from '@/utils/en-match-zh'
import { getAuditObject, cancel } from '@/api/stock-center/inbound-sheet'
import tooltipOver from '@/components/base-tooltip'
import AuditProcess from '@/components/AuditProcess/index'
import { SupplierManageInteractor, InboundSheet } from '@/core'
export default {
  name: 'SupplierManage',
  mixins: [customTableMixin],
  components: {
    BaseTable,
    ErpDraggable,
    SupplierManageSearch,
    AuditProcess,
    tooltipOver,
  },
  data() {
    return {
      loading: false,
      activeName: 'first',
      requireForm: {
        supplierCode: '',
      }, //请求参数
      tableData: [], // 表格数据
      total: 0, //分页总数
      pageNo: 1, //当前页
      pageLe: 10, //一页条数
      columns: [
        //table列字段
        {
          label: '供应商编码',
          prop: 'supplierCode',
          isShow: true,
          checkable: false,
          width: 120,
        },
        {
          label: '供应商名称',
          prop: 'supplierName',
          isShow: true,
          checkable: false,
          width: 250,
        },
        {
          label: '联系人',
          prop: 'linkman',
          isShow: true,
        },
        {
          label: '联系人电话',
          prop: 'phone',
          isShow: true,
        },
        {
          label: '联系人邮箱',
          prop: 'email',
          isShow: true,
          width: 180,
        },
        {
          label: '供应商等级',
          prop: 'supplierGrade',
          isShow: true,
          width: 150,
        },
        {
          label: '供应商性质',
          prop: 'supplierNatureName',
          isShow: true,
        },
        {
          label: '供应商类型',
          prop: 'supplierTypeName',
          isShow: true,
        },
        {
          label: '订单成交量',
          prop: 'turnover',
          isShow: false,
        },
        {
          label: '订单总金额',
          prop: 'total',
          isShow: false,
        },
        {
          label: '付款方式',
          prop: 'paymentName',
          isShow: false,
        },
        {
          label: '第三方认证',
          prop: 'cardName',
          isShow: false,
        },
        {
          label: '状态',
          prop: 'status',
          isShow: true,
        },
        {
          label: '创建人',
          prop: 'creatorName',
          isShow: true,
        },
        {
          label: '创建时间',
          prop: 'createTime',
          isShow: false,
        },
      ],
      userCustomizeColumnId: '', //自义定保存id
      selectRows: [], //表格勾选的数量
      drawer: false,
      size: '500px',
      dialogBlackVisible: false, // 黑名单弹窗
      blackForm: {
        blacklistReasons: '', // 作废理由
      },
      blackRules: {
        blacklistReasons: [
          { required: true, message: '请输入理由', trigger: 'blur' },
        ],
      },
      supplierIds: [], // 勾选的供应商数组
      currentSupplierId: '', // 当前点击的id
      attestationInfo: '', // 返回的企业认证信息

      auditObjectId: '', //审核id（供应商id）
      auditType: 10,
      cancelReason: '', //撤销原因
      cancelVisible: false, //撤销弹框
      isBlack: true, //是否可进行黑名单操作
    }
  },
  computed: {
    ...mapGetters({
      userInfo: 'user/userInfo',
    }),
    dragOptions() {
      return {
        animation: 600,
        group: 'description',
      }
    },
    finallyColumns() {
      return this.columns.filter((item) => item.isShow === true)
    },
  },
  filters: {
    //供应商标识
    signFilter(status) {
      const statusMap = {
        0: 'info',
        1: 'primary',
        2: 'success',
      }
      return statusMap[status]
    },
    //供应商审核状态
    statusFilter(status) {
      const statusMap = {
        0: 'info',
        1: 'primary',
        2: 'success',
        3: 'danger',
        4: 'info',
        5: 'info',
        6: 'success',
        7: 'danger',
      }
      return statusMap[status]
    },
  },
  created() {
    //获取自定义保存
    CustomCache.getColumns(6, this.userInfo.userId).then((res) => {
      if (res && res.columns) {
        this.userCustomizeColumnId = res.userCustomizeColumnId
        this.columns = res.columns
      }
    })
  },
  activated() {
    this.selectRows = []
    this.fetchData()
  },
  watch: {
    $route: function (val) {
      if (val.meta && val.meta.showParent) {
        this.selectRows = []
        this.fetchData()
      }
    },
  },
  methods: {
    //撤销
    revokeClick(row) {
      InboundSheet.getAuditObject({
        orderId: row.supplierId,
        auditType: 10,
        tenantId: this.userInfo.tenantId,
      }).then((res) => {
        if (res && res.code === '000000') {
          // 获取提交审核（撤销所用）auditObjectId
          this.auditObjectId = res.data.auditObjectId
          this.cancelVisible = true
        }
      })
    },
    //关闭撤销弹框
    onCancel() {
      this.cancelVisible = false
      setTimeout(() => {
        this.cancelReason = ''
      }, 400)
    },

    //确定撤销
    cancelSure() {
      if (!this.cancelReason) {
        return this.$message.warning('请输入撤销原因')
      }
      const {
        auditObjectId,
        auditStatus = 4,
        auditType = 6, //待修改
        cancelReason,
      } = this
      const { tenantId, userId, userName } = this.userInfo
      InboundSheet.cancel({
        auditObjectId,
        auditStatus: 4,
        auditType,
        tenantId,
        userId,
        userName,
        remarks: cancelReason,
      }).then((res) => {
        if (res.code === '000000') {
          this.$message.success('撤销成功')
          this.cancelVisible = false
          this.fetchData()
        }
      })
    },
    // 处理点击编辑
    editDetail(str, supplierId) {
      this.$router.push({
        path: '/client-supplier/supplier-manage-add',
        query: { str: str, supplierId: supplierId },
      })
    },

    // 获取列表数据
    async fetchData() {
      let response = await SupplierManageInteractor.supplierListPage({
        ...this.requireForm,
        pageNo: this.pageNo,
        pageLe: this.pageLe,
      })
      if (response.code === '000000') {
        this.tableData = response.data.data
        this.total = response.data.total
      }
    },
    //新增编辑客户
    addClick() {
      this.$router.push({
        path: '/client-supplier/supplier-manage-add',
      })
    },
    // 点击供应商编码
    supplierCodeClick(val) {
      this.$router.push({
        path: '/client-supplier/supplier-manage-detail',
        query: { supplierId: val },
      })
    },
    // 表格勾选
    setSelectRows(val) {
      this.supplierIds = []
      this.selectRows = val
      this.selectRows.forEach((ele) => {
        this.supplierIds.push(ele.supplierId)
      })

      //是否可进行黑名单操作
      this.isBlack = this.selectRows.every(
        (item) => item.creatorId === this.userInfo.userId
      )
    },
    // 点击加入黑名单
    addBlackList() {
      if (this.selectRows.length) {
        this.dialogBlackVisible = true
      } else {
        return this.$baseMessage(
          '请先选择要操作的供应商',
          'warning',
          false,
          'erp-hey-message-warning'
        )
      }
    },
    closeBlackDialog() {
      this.$refs['blackForm'].resetFields()
      this.dialogBlackVisible = false
    },

    // 保存黑名单
    saveBlackList() {
      let params = {
        supplierIds: this.supplierIds,
        blacklistReasons: this.blackForm.blacklistReasons,
      }
      this.$refs['blackForm'].validate(async (valid) => {
        if (valid) {
          this.loading = true
          let response = await SupplierManageInteractor.supplierAddBlacklist(params)
          this.loading = false
          if (response.code === '000000') {
            this.$message.success('加入黑名单成功！')
            this.fetchData()
            this.closeBlackDialog()
          }
        }
      })
    },
    //如果input为空
    searchInput(val) {
      if (val === '') {
        this.fetchData()
      }
    },

    //搜索searchHandleClick
    searchHandleClick() {
      this.pageNo = 1
      this.fetchData()
    },

    //高级筛选
    seniorSearch() {
      this.drawer = true
    },
    // 高级筛选关闭
    seniorSearchClose() {
      this.drawer = false
    },
    // 高级筛选触发
    searchClick(val) {
      this.pageNo = 1
      this.requireForm = val
      this.fetchData()
      this.seniorSearchClose()
    },

    //自定义显示保存
    operateSave() {
      let customizeData = JSON.stringify(this.columns)
      let params = {
        userCustomizeColumnId: this.userCustomizeColumnId,
        menuType: 6,
        userId: this.userInfo.userId,
        customizeData,
      }
      CustomCache.setColumns(params).then((res) => {
        if (res.code === '000000') {
          this.userCustomizeColumnId = res.data.userCustomizeColumnId
          return this.$baseMessage(
            '操作成功',
            'success',
            false,
            'erp-hey-message-success'
          )
        }
      })
    },

    //审核状态匹配
    supplierAuditStatus(val) {
      return supplierAuditStatus(val)
    },
  },
}
</script>

<style scoped lang="scss">
.main-height {
  height: calc(100% - 56px);
}
//自定义显示
.custom-table-checkbox {
  .el-checkbox {
    display: block !important;
    margin: 8px 0 8px 10px !important;
  }
}
.save-btn {
  border-top: 1px solid #ececec;
  padding-top: 10px;
  text-align: center;
}
.width-200 {
  width: 200px;
}
.supplierName-row {
  .supplierName-p {
    flex: 1;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    text-align: left;
  }
}
</style>
